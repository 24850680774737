import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
    imports: [
        DynamicDialogModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        StyleClassModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        TooltipModule,
        RippleModule,
        RouterModule,
        ButtonModule,
        MenuModule,
        ToastModule,
        DropdownModule,
        ConfirmDialogModule,
        DialogModule,
        InputMaskModule,
        TableModule,
        MultiSelectModule,
        ProgressSpinnerModule,
        IconFieldModule,
        InputIconModule,
        NgxDaterangepickerMd.forRoot()
    ],
    exports:[
        DynamicDialogModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        StyleClassModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        TooltipModule,
        RippleModule,
        RouterModule,
        ButtonModule,
        MenuModule,
        ToastModule,
        DropdownModule,
        ConfirmDialogModule,
        DialogModule,
        InputMaskModule,
        TableModule,
        MultiSelectModule,
        ProgressSpinnerModule,
        IconFieldModule,
        InputIconModule,
        NgxDaterangepickerMd
    ]
})
export class LayoutModule {}
